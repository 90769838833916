import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, InputNumber, Select, Popconfirm, Row, Col, Checkbox, Table, Modal } from 'antd'
import * as actions from '../actions'
import TextArea from 'antd/es/input/TextArea'
import * as sitesActions from '../../Sites/actions'
import EditTimeWindow from './EditTimeWindow'
import { CopyFilled, DeleteFilled } from '@ant-design/icons'
import CreateTimeWindow from './CreateTimeWindow'
import * as compaignsActions from '../../Companies/actions'
import * as productsActions from '../../Products/actions'
import * as processorsActions from '../../Processors/actions'
import EditAttempt from './EditAttempt'
import AddAttempt from './AddAttempt'

const UpdateRebatesCampaign = ({
  record,
  setRebatesCampaignsList,
  updateRebatesCampaign,
  campaigns,
  fetchCompaniesList,
  sites,
  processors,
  products,
  setSitesListForSelect,
  setProductsList,
  fetchProcessorsList,
}) => {
  const [isVisible, setVisible] = useState(false)
  const [campaignId, setCampaignId] = useState(record.campaignId)
  const [isRandomPrice, setIsRandomPrice] = useState(record.isRandomPrice)
  const [useAttempts, setUseAttempts] = useState(record.useAttempts ? true : false)
  const [timeWindows, setTimeWindows] = useState(record.timeWindows.sort((a, b) => a.day - b.day))
  const [attempts, setAttempts] = useState(
    record.rebatesAttempts ? record.rebatesAttempts.sort((a, b) => a.delay - b.delay) : [],
  )
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  useEffect(() => {
    isVisible && fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [fetchCompaniesList])
  useEffect(() => {
    if (isVisible) {
      setSitesListForSelect({ page: 1, size: 1000, filters: { campaignId: +campaignId } })
      setProductsList({ page: 1, size: 1000, filters: { campaignId: +campaignId } })
      fetchProcessorsList({ page: 1, size: 1000, filters: { campaignId: +campaignId } })
    }
  }, [campaignId, isVisible])
  const attemptsColumns = [
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Delay in Minutes',
      dataIndex: 'delay',
      key: 'delay',
    },
    {
      title: 'Actions',
      dataIndex: 'attemptsActions',
      key: 'attemptsActions',
      render: (_, record) => (
        <div>
          <EditAttempt attempt={record} editAttempt={editAttempt} />
          <Popconfirm
            title='Remove Attempt'
            description='Confirm Attempt Remove'
            onConfirm={() => deleteAttempt(record)}
            okText='Delete'
            cancelText='Cancel'
          >
            <DeleteFilled />
          </Popconfirm>
        </div>
      ),
    },
  ]

  const timeWindowsColumns = [
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      render: (_, record, index) => index + 1,
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
    },
    {
      title: 'MC Count',
      dataIndex: 'mcCount',
      key: 'mcCount',
    },
    {
      title: 'VI Count',
      dataIndex: 'visaCount',
      key: 'visaCount',
    },
    {
      title: 'Actions',
      dataIndex: 'timeWindowsActions',
      key: 'timeWindowsActions',
      render: (_, record) => (
        <div>
          <EditTimeWindow window={record} editTimeWindow={editTimeWindow} />
          <CopyFilled onClick={() => copyWindow(record)} />
          <Popconfirm
            title='Remove Window'
            description='Confrim Window Remove'
            onConfirm={() => deleteTimeWindow(record)}
            okText='Delete'
            cancelText='Cancel'
          >
            <DeleteFilled />
          </Popconfirm>
        </div>
      ),
    },
  ]
  const deleteTimeWindow = window => {
    const newTimeWindows = timeWindows
      .filter(timeWindow => timeWindow !== window)
      .map((timeWindow, index) => {
        return {
          ...timeWindow,
          day: index,
        }
      })
    setTimeWindows(newTimeWindows)
  }
  const copyWindow = window => {
    const newWindow = {}
    newWindow.startTime = window.startTime
    newWindow.endTime = window.endTime
    newWindow.mcCount = window.mcCount
    newWindow.visaCount = window.visaCount
    //window.key = timeWindows.length
    newWindow.day = timeWindows.length
    setTimeWindows([...timeWindows, newWindow])
  }

  const addTimeWindow = window => {
    window.startTime = window.startTime.format('HH:mm:ss')
    window.endTime = window.endTime.format('HH:mm:ss')
    //window.totalCount = window.mcCount + window.visaCount
    //window.key = timeWindows.length
    window.day = timeWindows.length
    setTimeWindows([...timeWindows, window])
  }
  const editTimeWindow = () => {
    setTimeWindows([
      ...timeWindows.map((timeWindow, index) => {
        return {
          ...timeWindow,
          day: index,
        }
      }),
    ])
  }
  console.log(timeWindows)

  const deleteAttempt = attempt => {
    const newAttempts = attempts.filter(campaignAttempt => campaignAttempt !== attempt)
    setAttempts(newAttempts)
  }
  const addAttempt = attempt => {
    attempt.key = attempts.length
    const newAttempts = [...attempts, attempt]
    setAttempts(newAttempts.sort((a, b) => a.delay - b.delay))
  }
  const editAttempt = () => {
    setAttempts([...attempts.sort((a, b) => a.delay - b.delay)])
  }

  const onFinishHandler = async values => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    await updateRebatesCampaign({
      id: +record.id,
      name: values.name,
      active: record.active,
      campaignId: +values.campaignId,
      siteId: +values.siteId,
      productId: +values.productId,
      shippingId: +values.shippingId,
      mid: +values.mid,
      repeatDays: values.repeatDays,
      isRandomPrice: isRandomPrice,
      price: +values.price,
      minPrice: +values.minPrice,
      maxPrice: +values.maxPrice,
      timeWindows: timeWindows,
      forceIp: values.forceIp,
      useAttempts: useAttempts,
      attempts: attempts.map(attempt => {
        return {
          price: attempt.price,
          delay: attempt.delay,
        }
      }),
    })
    if (campaignItem) {
      setRebatesCampaignsList({ filters: { campaignId: +campaignItem.id } })
    } else {
      setRebatesCampaignsList()
    }
    setVisible(false)
    //form.resetFields()
  }

  return (
    <>
      <i
        className='zmdi zmdi-edit zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item'
        title='Edit'
        type='primary'
        onClick={() => {
          form.resetFields()
          setVisible(true)
        }}
      />
      <Modal
        title='Edit Rebates Campaign'
        className={'rebatesCampaign_popup'}
        onClose={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        visible={isVisible}
        width={'90%'}
        footer={[
          <Button key='back' onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={() => form.submit()}>
            Edit
          </Button>,
        ]}
      >
        <Form
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          labelCol={{ span: 24, offset: 0 }}
          wrapperCol={{ span: 24, offset: 0 }}
        >
          <Row gutter={8}>
            <Col sm={24}>
              <Form.Item
                name='name'
                initialValue={record.name}
                label='Name'
                rules={[{ required: true, message: 'Please input Name!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='campaignId'
                label='Select a company'
                initialValue={record.campaignId}
                rules={[{ required: true, message: 'Select Company!' }]}
              >
                <Select
                  onSelect={value => setCampaignId(value)}
                  showSearch={true}
                  options={campaigns.map(item => {
                    return { value: item.id, label: item.name }
                  })}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='siteId'
                label='Select a siteId(campaign)'
                initialValue={record.siteId}
                rules={[{ required: true, message: 'Select siteId(campaign)!' }]}
              >
                <Select
                  showSearch={true}
                  options={sites
                    .sort((a, b) => a.externalId - b.externalId)
                    .map(item => {
                      return { value: item.externalId, label: `(${item.externalId}) ` + item.name }
                    })}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='productId'
                label='Select a productId'
                initialValue={record.productId}
                rules={[{ required: true, message: 'Select productId!' }]}
              >
                <Select
                  showSearch={true}
                  options={products
                    .sort((a, b) => a.externalId - b.externalId)
                    .map(item => {
                      return { value: item.externalId, label: `(${item.externalId}) ` + item.name }
                    })}
                />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Form.Item name='shippingId' label='Shipping Id' initialValue={record.shippingId}>
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Form.Item name='forceIp' label='Force IP Address' initialValue={record.forceIp}>
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                initialValue={record.mid}
                name='mid'
                label='Select a MID'
                rules={[{ required: true, message: 'Select MID!' }]}
              >
                <Select
                  showSearch={true}
                  options={[
                    { value: 0, label: 'Unset' },
                    ...processors
                      .sort((a, b) => a.externalId - b.externalId)
                      .map(item => {
                        return { value: item.externalId, label: `(${item.externalId}) ` + item.name }
                      }),
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name='repeatDays' label='Repeat Type' initialValue={record.repeatDays}>
                <Select
                  options={[
                    { value: 'last', label: 'Repeat Last' },
                    { value: 'cycle', label: 'Cycle' },
                    { value: 'stopAfter', label: 'Stop After Last Day' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                labelCol={{ span: 'auto', offset: 0 }}
                wrapperCol={{ span: 'auto', offset: 0 }}
                name='isRandomPrice'
                label='Use Random Price'
                valuePropName={'checkbox'}
                initialValue={record.isRandomPrice}
              >
                <Checkbox checked={isRandomPrice} onChange={() => setIsRandomPrice(!isRandomPrice)} />
              </Form.Item>
            </Col>
            {!isRandomPrice && (
              <Col sm={12}>
                <Form.Item
                  name='price'
                  initialValue={record.price}
                  label='Price'
                  rules={[{ required: true, message: 'Please input Price!' }]}
                >
                  <InputNumber min={0} precision={2} />
                </Form.Item>
              </Col>
            )}
            {isRandomPrice && (
              <Col sm={12}>
                <Form.Item
                  name='minPrice'
                  initialValue={record.minPrice}
                  label='Minimal Price'
                  rules={[{ required: true, message: 'Please input Minimal Price!' }]}
                >
                  <InputNumber min={0} precision={2} />
                </Form.Item>
              </Col>
            )}
            {isRandomPrice && (
              <Col sm={12}>
                <Form.Item
                  name='maxPrice'
                  initialValue={record.maxPrice}
                  label='Maximum Price'
                  rules={[{ required: true, message: 'Please input Maximum Price!' }]}
                >
                  <InputNumber min={0} precision={2} />
                </Form.Item>
              </Col>
            )}
            {!isRandomPrice && (
              <Col sm={24}>
                <Form.Item
                  labelCol={{ span: 'auto', offset: 0 }}
                  wrapperCol={{ span: 'auto', offset: 0 }}
                  name='useAttempts'
                  label='Use Attempts'
                  valuePropName={'checkbox'}
                  initialValue={record.useAttempts}
                >
                  <Checkbox checked={useAttempts} onChange={() => setUseAttempts(!useAttempts)} />
                </Form.Item>
              </Col>
            )}
          </Row>
          {!isRandomPrice && useAttempts && (
            <>
              <AddAttempt addAttempt={addAttempt} />
              <Table dataSource={attempts} columns={attemptsColumns} pagination={false} />
            </>
          )}
          <CreateTimeWindow addTimeWindow={addTimeWindow} />
          <Table
            rowKey={record => record.day}
            dataSource={timeWindows}
            columns={timeWindowsColumns}
            pagination={false}
          />
        </Form>
      </Modal>
    </>
  )
}
const mapStateToProps = store => ({
  campaigns: store.companies.list,
  processors: store.processors.list,
  products: store.products.list,
  sites: store.sites.sitesForSelect,
})
const mapDispatchToProps = {
  setRebatesCampaignsList: actions.setRebatesCampaignsList,
  updateRebatesCampaign: actions.updateRebatesCampaign,
  fetchCompaniesList: compaignsActions.fetchCompaniesList,
  setSitesListForSelect: sitesActions.fetchSitesListForSelect,
  setProductsList: productsActions.setProductsList,
  fetchProcessorsList: processorsActions.fetchProcessorsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRebatesCampaign)
